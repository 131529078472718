define("discourse/plugins/discourse-cartalk/discourse/templates/components/discourse-cartalk", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.showCarTalkHouseAds1}}
    {{cartalk-house-ad
      placement="post-bottom"
      setNum="1"
      postNumber=this.model.post_number
      topicId=this.model.topic_id
    }}
  {{/if}}
  {{#if this.model.showCarTalkHouseAds2}}
    {{cartalk-house-ad
      placement="post-bottom"
      setNum="2"
      postNumber=this.model.post_number
      topicId=this.model.topic_id
    }}
  {{/if}}
  */
  {
    "id": "qfj8OBLQ",
    "block": "[[[41,[30,0,[\"model\",\"showCarTalkHouseAds1\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"placement\",\"setNum\",\"postNumber\",\"topicId\"],[\"post-bottom\",\"1\",[30,0,[\"model\",\"post_number\"]],[30,0,[\"model\",\"topic_id\"]]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"showCarTalkHouseAds2\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"placement\",\"setNum\",\"postNumber\",\"topicId\"],[\"post-bottom\",\"2\",[30,0,[\"model\",\"post_number\"]],[30,0,[\"model\",\"topic_id\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"cartalk-house-ad\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/components/discourse-cartalk.hbs",
    "isStrictMode": false
  });
});