define("discourse/plugins/discourse-cartalk/discourse/templates/components/cartalk-house-ad", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.adHtml}}
    {{#if this.site.mobileView}}
      <div class="house-ad-label" style="width: 300px;">
        <h2>
          ADVERTISEMENT
        </h2>
      </div>
    {{else}}
      <div class="house-ad-label"><h2>ADVERTISEMENT</h2></div>
    {{/if}}
    <div id={{this.divId}} class="house-ad-unit">{{this.adHtml}}</div>
  {{/if}}
  */
  {
    "id": "rqqooHeH",
    "block": "[[[41,[30,0,[\"adHtml\"]],[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"house-ad-label\"],[14,5,\"width: 300px;\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"\\n        ADVERTISEMENT\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"house-ad-label\"],[12],[10,\"h2\"],[12],[1,\"ADVERTISEMENT\"],[13],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[15,1,[30,0,[\"divId\"]]],[14,0,\"house-ad-unit\"],[12],[1,[30,0,[\"adHtml\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"h2\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/components/cartalk-house-ad.hbs",
    "isStrictMode": false
  });
});