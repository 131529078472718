define("discourse/plugins/discourse-cartalk/discourse/templates/components/composer-make-model", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    {{combo-box
      name="cartalk-make"
      value=this.makeSelected
      content=this.makesForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.make"
    }}
    {{combo-box
      name="cartalk-make"
      value=this.modelSelected
      content=this.modelsForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.model"
      options=(hash disabled=this.modelsDisabled)
    }}
  {{else}}
    {{combo-box
      name="cartalk-make"
      value=this.makeSelected
      content=this.makesForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.choose_make"
    }}
    {{combo-box
      name="cartalk-make"
      value=this.modelSelected
      content=this.modelsForSelect
      optionValuePath="content.id"
      optionLabelPath="content.name"
      none="cartalk.choose_model"
      options=(hash disabled=this.modelsDisabled)
    }}
  {{/if}}
  */
  {
    "id": "Sk8LPaq9",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.make\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.model\",[28,[37,2],null,[[\"disabled\"],[[30,0,[\"modelsDisabled\"]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_make\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_model\",[28,[37,2],null,[[\"disabled\"],[[30,0,[\"modelsDisabled\"]]]]]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/components/composer-make-model.hbs",
    "isStrictMode": false
  });
});