define("discourse/plugins/discourse-cartalk/discourse/templates/components/cartalk-make-model", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    {{d-button translatedLabel="Make & Model" action=(action "togglePopup")}}
  {{else}}
    <a href {{action "togglePopup"}}>Make & Model</a>
  {{/if}}
  
  {{#if this.showPopup}}
    <div class="cartalk-mm-popup">
      {{combo-box
        name="cartalk-make"
        value=this.makeSelected
        content=this.makesForSelect
        optionValuePath="content.id"
        optionLabelPath="content.name"
        none="cartalk.choose_make"
      }}
  
      <br />
      <br />
  
      {{combo-box
        name="cartalk-make"
        value=this.modelSelected
        content=this.modelsForSelect
        optionValuePath="content.id"
        optionLabelPath="content.name"
        none="cartalk.choose_model"
        options=(hash disabled=this.modelsEmpty)
      }}
    </div>
  {{/if}}
  */
  {
    "id": "yD/ByVbB",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"translatedLabel\",\"action\"],[\"Make & Model\",[28,[37,2],[[30,0],\"togglePopup\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,6,\"\"],[4,[38,2],[[30,0],\"togglePopup\"],null],[12],[1,\"Make & Model\"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"showPopup\"]],[[[1,\"  \"],[10,0],[14,0,\"cartalk-mm-popup\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_make\"]]]],[1,\"\\n\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_model\",[28,[37,7],null,[[\"disabled\"],[[30,0,[\"modelsEmpty\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"a\",\"div\",\"combo-box\",\"br\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/components/cartalk-make-model.hbs",
    "isStrictMode": false
  });
});