define("discourse/plugins/discourse-cartalk/discourse/templates/connectors/user-card-metadata/discourse-cartalk", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>
    {{#if this.user.custom_fields.make_tag}}
      {{discourse-tag this.user.custom_fields.make_tag}}
    {{/if}}
    {{#if this.user.custom_fields.model_tag}}
      {{discourse-tag this.user.custom_fields.model_tag}}
    {{/if}}
  </h3>
  */
  {
    "id": "J45IJFfA",
    "block": "[[[10,\"h3\"],[12],[1,\"\\n\"],[41,[30,0,[\"user\",\"custom_fields\",\"make_tag\"]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"user\",\"custom_fields\",\"make_tag\"]]],null]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"user\",\"custom_fields\",\"model_tag\"]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"user\",\"custom_fields\",\"model_tag\"]]],null]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"h3\",\"if\",\"discourse-tag\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/connectors/user-card-metadata/discourse-cartalk.hbs",
    "isStrictMode": false
  });
});