define("discourse/plugins/discourse-cartalk/discourse/templates/connectors/composer-fields/discourse-cartalk", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.canCategorize}}
    {{composer-make-model composer=this.model}}
  {{/if}}
  */
  {
    "id": "s4OBLVhZ",
    "block": "[[[41,[30,0,[\"model\",\"canCategorize\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"composer\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"composer-make-model\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/connectors/composer-fields/discourse-cartalk.hbs",
    "isStrictMode": false
  });
});