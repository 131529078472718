define("discourse/plugins/discourse-cartalk/discourse/templates/connectors/user-profile-primary/discourse-cartalk", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.custom_fields.make_tag}}
    {{discourse-tag this.model.custom_fields.make_tag}}
  {{/if}}
  {{#if this.model.custom_fields.model_tag}}
    {{discourse-tag this.model.custom_fields.model_tag}}
  {{/if}}
  */
  {
    "id": "ROIhIglV",
    "block": "[[[41,[30,0,[\"model\",\"custom_fields\",\"make_tag\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"model\",\"custom_fields\",\"make_tag\"]]],null]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"model\",\"custom_fields\",\"model_tag\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"model\",\"custom_fields\",\"model_tag\"]]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"discourse-tag\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/templates/connectors/user-profile-primary/discourse-cartalk.hbs",
    "isStrictMode": false
  });
});